import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '@lean-body/components/styles';
export var CourseDescription = function (_a) {
    var course = _a.course, classObject = _a.classObject;
    return (React.createElement("div", { className: css(styles.container, classObject) },
        React.createElement("div", { className: css(styles.label) }, course.recommendText),
        React.createElement("div", { className: css(styles.description) }, course.description)));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'left',
    },
    label: {
        fontWeight: 700,
        fontSize: 18,
        letterSpacing: '0.08em',
        color: Colors.Black,
        marginBottom: 15,
    },
    description: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '160%',
        whiteSpace: 'pre-wrap',
    },
});
